/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:157866b8-071c-4520-ba64-85cf7a90db15",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_F4vk858e9",
    "aws_user_pools_web_client_id": "4pk2eks5iogdsprno13tbpmbfg",
    "oauth": {},
    "aws_user_files_s3_bucket": "pronia-test",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_content_delivery_bucket": "proniahosting-test",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d2ozriwltl48x8.cloudfront.net"
};


export default awsmobile;
